import { useContext, useEffect, useState } from 'react';
import { MainContext } from '../App';
import { getGerencias } from '../services/request/requestPortafolio';
import * as PERMISSIONS from '../constants/permissions';

// Mapeo de siglas a permisos para acceder a una Gerencia
const SIGLA_PERMISSIONS_MAP = {
  GGN: PERMISSIONS.VER_GERENCIA_GGN,
  GAF: PERMISSIONS.VER_GERENCIA_GAF,
  GIT: PERMISSIONS.VER_GERENCIA_GIT,
  GEI: PERMISSIONS.VER_GERENCIA_GEI,
  GMI: PERMISSIONS.VER_GERENCIA_GMI,
  GPO: PERMISSIONS.VER_GERENCIA_GPO,
  GMB: PERMISSIONS.VER_GERENCIA_GMB,
  GOP: PERMISSIONS.VER_GERENCIA_GOP,
  GDN: PERMISSIONS.VER_GERENCIA_GDN,
  CDP: PERMISSIONS.VER_GERENCIA_CDP,
  CZS: PERMISSIONS.VER_GERENCIA_CZS,
  PSI: PERMISSIONS.VER_GERENCIA_PSI,
  PPE: PERMISSIONS.VER_GERENCIA_PPE,
  TEC: PERMISSIONS.VER_GERENCIA_TEC,
  GZS: PERMISSIONS.VER_GERENCIA_GZS,
  GDO: PERMISSIONS.VER_GERENCIA_GDO,
  RSE: PERMISSIONS.VER_GERENCIA_RSE,
  GPE: PERMISSIONS.VER_GERENCIA_GPE,
  GCM: PERMISSIONS.VER_GERENCIA_GCM,
  OTC: PERMISSIONS.VER_GERENCIA_OTC,
  CDZ: PERMISSIONS.VER_GERENCIA_CDZ,
  GRE: PERMISSIONS.VER_GERENCIA_GRE,
  GIN: PERMISSIONS.VER_GERENCIA_GIN,
};

const useGerenciasUser = (agregarGerenciaUsuario = false) => {
  const [gerencias, setGerencias] = useState([]);
  const { usuarioSesion, permisos } = useContext(MainContext);

  const processGerencias = async () => {
    const gerenciasData = await getGerencias({ is_eliminado: false });
    if (gerenciasData) {
      const gerenciasAccesoUsuario = gerenciasData.filter((gerencia) => {
        const permiso = SIGLA_PERMISSIONS_MAP[gerencia.sigla];
        return permiso && permisos[permiso];
      });

      if (agregarGerenciaUsuario) {
        const gerenciaUsuario = gerenciasData.find((gerencia) => gerencia._id === usuarioSesion.gerencia);
        if (gerenciaUsuario) {
          gerenciasAccesoUsuario.push(gerenciaUsuario);
        }
      }
      setGerencias(gerenciasAccesoUsuario);
    }
  };

  useEffect(() => {
    processGerencias();
  }, []);

  return { gerencias };
};

export default useGerenciasUser;

import React, { createContext, useCallback, useState } from 'react';
import Page from './page';
import { getConsultaBienes } from '../../../services/request/requestBienes';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';
import XLSX from 'xlsx';
import useSWR from 'swr';
import { getTiposRecursos } from '../../../services/request/requestTipoRecurso';
import useGerenciasUser from '../../../hooks/useGerenciasUser';

export const ListadoActivosContext = createContext({
  data: [],
  setData: () => {},
  mostrarResultado: false,
  setMostrarResultado: () => {},
  triggerSearch: () => {},
  setTriggerSearch: () => {},
  handleExport: () => {},
  handleVerActivo: () => {},
  filter: {},
  setFilter: () => {},
  isLoading: true,
  tiposRecursos: [],
  gerencias: [],
});

const fetcher = (url, filter) => getConsultaBienes(filter);

export default function ListadoBienes(props) {
  const [mostrarResultado, setMostrarResultado] = useState(false);
  const history = useHistory();
  const [OpenSeleccionados, SetOpenSeleccionados] = useState(false);
  const [OpenSeleccionadosBaja, SetOpenSeleccionadosBaja] = useState(false);
  const { gerencias } = useGerenciasUser(true);
  const [filter, setFilter] = useState({});
  const { data: tiposRecursos } = useSWR('get:tipos-recursos', (key) => getTiposRecursos({}), { refreshInterval: 0 });
  const [swrKey, setSwrKey] = useState(null);

  const {
    data: bienes,
    error,
    isLoading,
    mutate,
  } = useSWR(swrKey, fetcher, {
    revalidateOnFocus: false, //evita peticiones extras cuando la pagina pierde el foco
  });

  const triggerSearch = useCallback(
    async (newFilter) => {
      setMostrarResultado(true);
      setFilter(newFilter);
      setSwrKey(['get:bienes', newFilter]);
      mutate(); // Disparar la recarga de datos con los nuevos filtros
    },
    [mutate],
  );

  const handleExport = (columns) => {
    const wb = XLSX.utils.book_new();
    const wbdata = bienes.map((a) => {
      const row = {};
      for (let c in columns) {
        const col = columns[c];
        if (col.title === 'Selección') continue;
        if (col.hidden) continue;
        row[col.title] = col.field && a[col.field] ? a[col.field] : '';
        if (col.title === 'Activado') row[col.title] = a.is_activo_contable;
      }
      return row;
    });
    let ws = XLSX.utils.json_to_sheet(wbdata);
    XLSX.utils.book_append_sheet(wb, ws, 'Activos');
    XLSX.writeFile(wb, 'activos.xlsx');
  };

  const handleVerActivo = (rowData) => {
    history.push(ROUTES.VER_ACTIVO.replace(':id', rowData._id));
  };

  return (
    <ListadoActivosContext.Provider
      value={{
        data: bienes ?? [],
        setData: (d) => null,
        handleExport,
        handleVerActivo,
        triggerSearch,
        setMostrarResultado,
        mostrarResultado,
        isLoading,
        OpenSeleccionados,
        SetOpenSeleccionados,
        OpenSeleccionadosBaja,
        SetOpenSeleccionadosBaja,
        tiposRecursos,
        gerencias,
      }}
    >
      <Page />
    </ListadoActivosContext.Provider>
  );
}
